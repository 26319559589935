import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { FaChevronDown, FaUserTie, FaUsers, FaShieldAlt, FaInfoCircle, FaBullhorn, FaBookOpen, FaPhone, FaBlog, FaNewspaper, FaVideo, FaCalendarAlt, FaPodcast, FaComments, FaEnvelope, FaQuestionCircle, FaRoute, FaHandshake, FaGraduationCap, FaBuilding, FaShareAlt, FaTrophy, FaBriefcase, FaGift, FaChartLine, FaRocket } from 'react-icons/fa';
import Styles from './Header.module.scss';
import { appLevelConstants } from '../../../constants/AppLevelConstants';
import { getMenuListData } from '../../../service/HeaderService';
import { addApiData } from '../../../redux/appDataSlice';
import { HiMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { urls } from "../../../utils/urls";

// const Links = ({ setTpenDropdown }) => {
//   const wi = window.innerWidth;
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   let navigate = useNavigate();
//   const location = useLocation();
//   const currentPath = location.pathname;
//   const [isSubDropdownOpen, setIsSubDropdownOpen] = useState(false);
//   const [subDropdownData, setSubDropdownData] = useState([]);
//   const [openDropdown, setOpenDropdown] = useState("");
//   const dispatch = useDispatch();
//   const dropdownRef = useRef(null);
//   const componentRef = useRef(null); // Add this ref

//   const handleScreenWidthChange = () => {
//     setScreenWidth(window.innerWidth);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", handleScreenWidthChange);
//     return () => {
//       window.removeEventListener("resize", handleScreenWidthChange);
//     };
//   }, []);

//   useEffect(() => {
//     getPrograms();
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (e) => {
//       if (componentRef.current && !componentRef.current.contains(e.target)) {
//         setOpenDropdown(""); // Close the dropdown if clicked outside
//       }
//     };

//     document.addEventListener("click", handleClickOutside);

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, []);

//   const handleSubDropdownToggle = () => {
//     setIsSubDropdownOpen(!isSubDropdownOpen);
//   };

//   const getPrograms = async () => {
//     try {
//       const response = await getMenuListData();
//       dispatch(addApiData(response.data.data));
//       let headers = response.data?.data?.individual_program?.map((item) => ({
//         id: item.id,
//         title: item.title.split("_").join(" "),
//         to: "/individualPrograms",
//       }));
//       setSubDropdownData(headers);
//     } catch (error) {
//       console.log(error, "error++");
//     }
//   };

//   const toggleDropdown = (dropdownName) => {
//     if (openDropdown === dropdownName) {
//       setOpenDropdown("");
//     } else {
//       setOpenDropdown(dropdownName);
//     }
//   };

//   const handleProgramSelect = (to, title) => {
//     console.log('Program selected:', title); 
//     setOpenDropdown(""); // Close the dropdown
//     navigate(`${to}/${title.split(" ").join("_")}`);
//   };

//   const handleAboutUsSelect = (to, title) => {
//     console.log('About Us selected:', title); 
//     setOpenDropdown(""); // Close the dropdown
//     navigate(to); 
//   };

//   const aboutUsDropdownData = [
//     { id: "0", title: "About Us", to: "/aboutUs" },
//     { id: "1", title: "Rajesh Bhat", to: "/aboutUs/rajesh-bhat" },
//     { id: "2", title: "Vision and Mission", to: "/aboutUs/vision-and-mission" },
//     { id: "3", title: "Founders", to: "/aboutUs/founders" },
//     { id: "4", title: "Community", to: "/aboutUs/community" },
//     { id: "5", title: "Publication", to: "/aboutUs/publication" }
//   ];

//   useEffect(() => {
//     appLevelConstants.Header.headers.map((val) => {
//       if (val.to === currentPath) {
//         setActiveItem(val);
//       } else if (appLevelConstants.Programs.includes(currentPath)) {
//         setActiveItem(currentPath);
//       }
//     });
//   }, [currentPath]);

//   const [activeItem, setActiveItem] = useState({});
//   const handleItemClick = (item) => {
//     window.scrollTo(0, 0);
//     setActiveItem(item);
//     setTpenDropdown(false);
//     if (wi <= "768") {
//       setTpenDropdown(false);
//     }
//     if (item.target === "_blank") {
//       window.open("https://workshops.iamironlady.com/masterclass_app", "_blank", "noopener noreferrer");
//     }
//   };

//   return (
//     <motion.ul className={Styles["menulist-ul"]} ref={componentRef}>
//       {appLevelConstants.Header.headers.map((item, i) => (
//         <motion.li className={Styles["menulist-li"]} key={i} ref={dropdownRef}>
//           <NavLink
//             className={`${Styles["menu-list-a"]} ${Styles["hover-underline-animation"]}`}
//             to={item.to}
//             onClick={() => setIsSubDropdownOpen(false)}
//           >
//             {item.name}
//           </NavLink>
//           {(item.name === "Programs" || item.name === "About Us") && (
//             <>
//               <FaChevronDown
//                 className={Styles["dropdown-icon"]}
//                 onClick={() => toggleDropdown(item.name)}
//               />
//               {openDropdown === item.name && (
//                 <ul
//                   className={item.name === "Programs" ? Styles["program-submenu"] : Styles["about-us-submenu"]}
//                   ref={dropdownRef}
//                 >
//                   {item.name === "Programs" &&
//                     subDropdownData.map((program, index) => {
//                       const programIcons = {
//                         "Leadership Essential Program": <FaUserTie />,
//                         "100 Board Members Program": <FaUsers />,
//                         "Master of Business Warfare": <FaShieldAlt />,
//                       };

//                       return (
//                         <li key={program.id} className={Styles["submenu-item-li"]}>
//                           <button
//                             style={{
//                               borderBottom: index !== subDropdownData.length - 1 ? "1px solid white" : "none",
//                             }}
//                             className={Styles["submenu-items"]}
//                             onClick={() => handleProgramSelect(program.to, program.title)}
//                           >
//                             <span className={Styles["submenu-icon"]}>
//                               {programIcons[program.title] || <FaUserTie />}
//                             </span>
//                             {program.title}
//                           </button>
//                         </li>
//                       );
//                     })}
//                   {item.name === "About Us" &&
//                     aboutUsDropdownData.map((aboutItem) => {
//                       const aboutUsIcons = {
//                         "About Us": <FaInfoCircle />,
//                         "Rajesh Bhat": <FaUserTie />,
//                         "Vision and Mission": <FaBullhorn />,
//                         "Founders": <FaUsers />,
//                         "Community": <FaUsers />,
//                         "Publication": <FaBookOpen />,
//                       };

//                       return (
//                         <li key={aboutItem.id} className={Styles["submenu-item-li"]}>
//                           <button
//                             className={Styles["submenu-items"]}
//                             onClick={() => handleAboutUsSelect(aboutItem.to, aboutItem.title)}
//                           >
//                             <span className={Styles["submenu-icon"]}>
//                               {aboutUsIcons[aboutItem.title] || <FaInfoCircle />}
//                             </span>
//                             {aboutItem.title}
//                           </button>
//                         </li>
//                       );
//                     })}
//                 </ul>
//               )}
//             </>
//           )}
//         </motion.li>
//       ))}
//     </motion.ul>
//   );
// };


const Links = ({ setTpenDropdown }) => {
  const wi = window.innerWidth;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isSubDropdownOpen, setIsSubDropdownOpen] = useState(false);
  const [subDropdownData, setSubDropdownData] = useState([]);
  const [openDropdown, setOpenDropdown] = useState("");
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const componentRef = useRef(null);

  const handleScreenWidthChange = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidthChange);
    return () => {
      window.removeEventListener("resize", handleScreenWidthChange);
    };
  }, []);

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setOpenDropdown("");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSubDropdownToggle = () => {
    setIsSubDropdownOpen(!isSubDropdownOpen);
  };

  const getPrograms = async () => {
    try {
      const response = await getMenuListData();
      dispatch(addApiData(response.data.data));
      let headers = response.data?.data?.individual_program?.map((item) => ({
        id: item.id,
        title: item.title.split("_").join(" "),
        to: "/individualPrograms",
      }));

      headers = headers.map((program) => {
        let description = "";
        switch (program.title) {
          case "Leadership Essentials Program":
            description = "Lead fearlessly and break limits in 4 weeks";
            break;
          case "100 Board Members Program":
            description = "Master politics, lead boldly in 6 months";
            break;
          case "Master of Business Warfare":
            description = "1-year program for C-suite mastery";
            break;
          default:
            description = "Explore this program in detail.";
        }
        return { ...program, description };
      });

      setSubDropdownData(headers);
    } catch (error) {
      console.log(error, "error++");
    }
  };

  const toggleDropdown = (dropdownName) => {
    if (openDropdown === dropdownName) {
      setOpenDropdown("");
    } else {
      setOpenDropdown(dropdownName);
    }
  };

  const handleProgramSelect = (to, title) => {
    setOpenDropdown("");
    navigate(`${to}/${title.split(" ").join("_")}`);
  };

  const handleAboutUsSelect = (to, title) => {
    console.log('About Us selected:', title);
    setOpenDropdown("");
    navigate(to);
  };

  const handleResourcesSelect = (to, title) => {
    console.log('Resource selected:', title);
    setOpenDropdown("");
    navigate(to);
  };

  const handleContactUsSelect = (to, title) => {
    console.log('Contact Us selected:', title);
    setOpenDropdown("");
    navigate(to);
  };

  const handleMasterClassSelect = (to, title) => {
    console.log('MasterClass selected:', title);
    setOpenDropdown("");
    navigate(to);
  };

  const aboutUsDropdownData = [
    { id: "0", title: "About Us", to: "/aboutUs", description: "Discover how we're empowering women to lead with confidence." },
    // { id: "1", title: "Rajesh Bhat", to: "/aboutUs/rajesh-bhat", description: "Get inspired by Rajesh Bhat, the visionary behind Iron Lady." },
    // { id: "2", title: "Vision and Mission", to: "/aboutUs/vision-and-mission", description: "Reaching a million women to the top." },
    { id: "3", title: "Founders", to: "/aboutUs/founders", description: "Meet the visionaries behind Iron Lady" },
    { id: "4", title: "Community", to: "/latest", description: "Join a powerful network shaping future leaders" },
    { id: "5", title: "Publication", to: "https://aninews.in/news/business/global-leaders-from-iron-lady-come-together-to-celebrate-the-shakti-within-indian-women-and-announce-50-under-50-awards20241122120704/", description: "Discover stories, insights, and monthly highlights" }
  ];

  const resourcesDropdownData = [
    { id: "0", title: "Blogs", to: "/blogs", description: "Everything that you need to fast-track" },
    // { id: "1", title: "Articles", to: "/articles", description: "Reads on leadership insights" },
    { id: "2", title: "Events", to: "https://events.iamironlady.com", description: "Workshops for women leaders" },
    { id: "3", title: "Video Resources", to: "/resources/video-resources", description: "Leadership strategies videos" },
    { id: "4", title: "Case Study", to: "/impact", description: "Real Life & Leadership success stories" },
    // { id: "5", title: "Podcasts", to: "/resources/podcasts", description: "Leadership talks and insights" },
    // { id: "6", title: "Testimonials", to: "/resources/testimonials", description: "Stories of success & growth" },
    // { id: "7", title: "Success Roadmaps", to: "/resources/success-roadmaps", description: "Leadership strategies guide" },
    { id: "8", title: "Newsletter", to: "/newsletter", description: "Iron Lady Speaks monthly updates" },
    { id: "9", title: "FAQ", to: "/resources/faq", description: "We have your questions answered" }
  ];

  const contactUsDropdownData = [
    { id: "0", title: "Contact Us", to: "/contactUs", description: "Have questions? We’re here to help." },
    { id: "1", title: "Partnership", to: "/partnership", description: "Explore partnership opportunities with us." },
    { id: "2", title: "Scholarship", to: "/scholarship", description: "Learn how our scholarships can support you." },
    { id: "3", title: "Corporate Program", to: "/corporateprogram", description: "Transform your workplace with our expert programs." },
    { id: "4", title: "Referral Program", to: "/champions", description: "Refer friends and earn exciting rewards." },
    { id: "5", title: "Winner Bench", to: "https://www.winnerbench.com", description: "Turning teenagers into fearless leaders." },
    { id: "6", title: "C-Suite League", to: "https://www.csuiteleague.com", description: "Join a community of top-level leaders." },
    { id: "7", title: "Careers", to: "https://careers.iamironlady.com/jobs/Careers", description: "Explore career opportunities with us." },
  ];

  const masterClassDropdownData = [
    { id: "0", title: "2 Day Leadership Masterclass", to: "https://workshops.iamironlady.com/masterclass_app", description: "Lead confidently with impact" },
    // { id: "1", title: "2 Hours Growth Accelerator", to: "https://workshops.iamironlady.com/2-Hours-masterclass", description: "Strategies to boost your growth" },
    { id: "2", title: "Fast track to 100 Board Members", to: "https://workshops.iamironlady.com/FastTrackto100BoardMembers", description: "Your path to boardroom success" },
  ];


  useEffect(() => {
    appLevelConstants.Header.headers.map((val) => {
      if (val.to === currentPath) {
        setActiveItem(val);
      } else if (appLevelConstants.Programs.includes(currentPath)) {
        setActiveItem(currentPath);
      }
    });
  }, [currentPath]);

  const [activeItem, setActiveItem] = useState({});
  const handleItemClick = (item) => {
    window.scrollTo(0, 0);
    setActiveItem(item);
    setTpenDropdown(false);
    if (wi <= "768") {
      setTpenDropdown(false);
    }
    if (item.target === "_blank") {
      window.open("https://workshops.iamironlady.com/masterclass_app", "_blank", "noopener noreferrer");
    }
  };

  return (
    <motion.ul className={Styles["menulist-ul"]} ref={componentRef}>
      {appLevelConstants.Header.headers.map((item, i) => (
        <motion.li className={Styles["menulist-li"]} key={i} ref={dropdownRef}>
          <NavLink
            className={`${Styles["menu-list-a"]} ${Styles["hover-underline-animation"]}`}
            to={item.to}
            onClick={() => { setIsSubDropdownOpen(false); setOpenDropdown(""); toggleDropdown(item.name) }}
          >
            {item.name}
          </NavLink>
          {(item.name === "Programs" || item.name === "About Us" || item.name === "Resources" || item.name === "Contact Us" || item.name === "MasterClass") && (
            <>
              <FaChevronDown
                className={Styles["dropdown-icon"]}
                onClick={() => toggleDropdown(item.name)}
              />
              {openDropdown === item.name && (
                <ul
                  className={
                    item.name === "Programs"
                      ? Styles["main-program-submenu"]
                      : item.name === "About Us"
                        ? Styles["about-us-submenu"]
                        : item.name === "Resources"
                          ? Styles["resources-submenu"]
                          : item.name === "Contact Us"
                            ? Styles["contact-us-submenu"]
                            : item.name === "MasterClass"
                              ? Styles["masterclass-submenu"]
                              : ""
                  }
                  ref={dropdownRef}
                >

                  {item.name === "Programs" &&
                    <div className={Styles["main-program-container"]}>
                      {subDropdownData.map((program, index) => {
                        const programIcons = {
                          "Leadership Essential Program": <FaUserTie />,
                          "100 Board Members Program": <FaUsers />,
                          "Master of Business Warfare": <FaShieldAlt />,
                        };

                        return (
                          <div key={program.id} className={Styles["main-program-item"]}>
                            <button
                              className={Styles["main-program-button"]}
                              onClick={() => handleProgramSelect(program.to, program.title)}
                            >
                              <span className={Styles["main-program-icon"]}>
                                {programIcons[program.title] || <FaUserTie />}
                              </span>
                              <div className={Styles["main-program-content"]}>
                                <div className={Styles["main-program-title"]}>{program.title}</div>
                                <div className={Styles["main-program-description"]}>{program.description}</div>
                              </div>
                            </button>
                          </div>
                        );
                      })}
                    </div>}

                  {item.name === "About Us" &&
                    <div className={Styles["about-us-container"]}>
                      {aboutUsDropdownData.map((aboutItem) => {
                        const aboutUsIcons = {
                          "About Us": <FaInfoCircle />,
                          "Rajesh Bhat": <FaUserTie />,
                          "Vision and Mission": <FaBullhorn />,
                          "Founders": <FaUsers />,
                          "Community": <FaUsers />,
                          "Publication": <FaBookOpen />,
                        };

                        const isExternalLink = aboutItem.to.startsWith("http");

                        return (
                          <div key={aboutItem.id} className={Styles["about-us-item"]}>
                            {isExternalLink ? (
                              <a
                                href={aboutItem.to}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={Styles["about-us-button"]}
                                style={{ textDecoration: 'none', fontSize: '13.3333px' }}
                              >
                                <span className={Styles["about-us-icon"]}>
                                  {aboutUsIcons[aboutItem.title] || <FaInfoCircle />}
                                </span>
                                <div className={Styles["about-us-content"]}>
                                  <div className={Styles["about-us-title"]}>{aboutItem.title}</div>
                                  <div className={Styles["about-us-description"]}>{aboutItem.description}</div>
                                </div>
                              </a>
                            ) : (
                              <button
                                className={Styles["about-us-button"]}
                                onClick={() => handleAboutUsSelect(aboutItem.to, aboutItem.title)}
                              >
                                <span className={Styles["about-us-icon"]}>
                                  {aboutUsIcons[aboutItem.title] || <FaInfoCircle />}
                                </span>
                                <div className={Styles["about-us-content"]}>
                                  <div className={Styles["about-us-title"]}>{aboutItem.title}</div>
                                  <div className={Styles["about-us-description"]}>{aboutItem.description}</div>
                                </div>
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  }

                  {item.name === "Resources" &&
                    <div className={Styles["resources-container"]}>
                      {resourcesDropdownData.map((resourceItem) => {
                        const resourcesIcons = {
                          "Blogs": <FaBlog />,
                          "Articles": <FaNewspaper />,
                          "Events": <FaCalendarAlt />,
                          "Video Resources": <FaVideo />,
                          "Case Study": <FaBookOpen />,
                          "Podcasts": <FaPodcast />,
                          "Testimonials": <FaComments />,
                          "Success Roadmaps": <FaRoute />,
                          "Newsletter": <FaEnvelope />,
                          "FAQ": <FaQuestionCircle />,
                        };

                        const isExternalLink = resourceItem.to.startsWith("http");

                        return (
                          <div key={resourceItem.id} className={Styles["resources-item"]}>
                            {isExternalLink ? (
                              <a
                                href={resourceItem.to}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={Styles["resources-button"]}
                                style={{ textDecoration: 'none', fontSize: '13.3333px' }}
                              >
                                <span className={Styles["resources-icon"]}>
                                  {resourcesIcons[resourceItem.title] || <FaQuestionCircle />}
                                </span>
                                <div className={Styles["resources-content"]}>
                                  <div className={Styles["resources-title"]}>{resourceItem.title}</div>
                                  <div className={Styles["resources-description"]}>{resourceItem.description}</div>
                                </div>
                              </a>
                            ) : (
                              <button
                                className={Styles["resources-button"]}
                                onClick={() => handleResourcesSelect(resourceItem.to, resourceItem.title)}
                              >
                                <span className={Styles["resources-icon"]}>
                                  {resourcesIcons[resourceItem.title] || <FaQuestionCircle />}
                                </span>
                                <div className={Styles["resources-content"]}>
                                  <div className={Styles["resources-title"]}>{resourceItem.title}</div>
                                  <div className={Styles["resources-description"]}>{resourceItem.description}</div>
                                </div>
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  }


                  {item.name === "Contact Us" &&
                    <div className={Styles["contact-us-container"]}>
                      {contactUsDropdownData.map((contactItem) => {
                        const contactUsIcons = {
                          "Contact Us": <FaEnvelope />,
                          "Partnership": <FaHandshake />,
                          "Scholarship": <FaGraduationCap />,
                          "Corporate Program": <FaBuilding />,
                          "Referral Program": <FaGift />,
                          "Winner Bench": <FaTrophy />,
                          "C-Suite League": <FaBriefcase />,
                          "Careers": <FaUserTie />,
                        };

                        const isExternalLink = contactItem.to.startsWith("http");

                        return (
                          <div key={contactItem.id} className={Styles["contact-us-item"]}>
                            {isExternalLink ? (
                              <a
                                href={contactItem.to}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={Styles["contact-us-button"]}
                                style={{ textDecoration: 'none', fontSize: '13.3333px' }}
                              >
                                <span className={Styles["contact-us-icon"]}>
                                  {contactUsIcons[contactItem.title] || <FaQuestionCircle />}
                                </span>
                                <div className={Styles["contact-us-content"]}>
                                  <div className={Styles["contact-us-title"]}>{contactItem.title}</div>
                                  <div className={Styles["contact-us-description"]}>{contactItem.description}</div>
                                </div>
                              </a>
                            ) : (
                              <button
                                className={Styles["contact-us-button"]}
                                onClick={() => handleContactUsSelect(contactItem.to, contactItem.title)}
                              >
                                <span className={Styles["contact-us-icon"]}>
                                  {contactUsIcons[contactItem.title] || <FaQuestionCircle />}
                                </span>
                                <div className={Styles["contact-us-content"]}>
                                  <div className={Styles["contact-us-title"]}>{contactItem.title}</div>
                                  <div className={Styles["contact-us-description"]}>{contactItem.description}</div>
                                </div>
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  }

                  {item.name === "MasterClass" &&
                    <div className={Styles["masterclass-container"]}>
                      {masterClassDropdownData.map((masterclassItem) => {
                        const masterclassIcons = {
                          "2 Day Leadership Masterclass": <FaUserTie />,
                          "2 Hours Growth Accelerator": <FaChartLine />,
                          "Fast track to 100 Board Members": <FaRocket />,
                        };

                        const isExternalLink = masterclassItem.to.startsWith("http");

                        return (
                          <div key={masterclassItem.id} className={Styles["masterclass-item"]}>
                            {isExternalLink ? (
                              <a
                                href={masterclassItem.to}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${Styles["masterclass-button"]}`}
                                style={{ textDecoration: 'none', fontSize: '13.3333px' }}
                              >
                                <span className={Styles["masterclass-icon"]}>
                                  {masterclassIcons[masterclassItem.title] || <FaUserTie />}
                                </span>
                                <div className={Styles["masterclass-content"]}>
                                  <div className={Styles["masterclass-title"]}>{masterclassItem.title}</div>
                                  <div className={Styles["masterclass-description"]}>{masterclassItem.description}</div>
                                </div>
                              </a>
                            ) : (
                              <button
                                className={Styles["masterclass-button"]}
                                onClick={() => handleProgramSelect(masterclassItem.to, masterclassItem.title)}
                              >
                                <span className={Styles["masterclass-icon"]}>
                                  {masterclassIcons[masterclassItem.title] || <FaUserTie />}
                                </span>
                                <div className={Styles["masterclass-content"]}>
                                  <div className={Styles["masterclass-title"]}>{masterclassItem.title}</div>
                                  <div className={Styles["masterclass-description"]}>{masterclassItem.description}</div>
                                </div>
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  }

                </ul>
              )}
            </>
          )}
        </motion.li>
      ))}
    </motion.ul>
  );
};


export default function Header() {
  const screenWidth = window.innerWidth;
  let [openDropdown, setTpenDropdown] = useState(false);
  const [showLogo, setShowLogo] = useState("");
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const currentUrl = location.pathname;
    setShowLogo(currentUrl);
  }, [location]);

  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    setShowDiv(location.pathname === "/" || location.pathname === "/facebook" || location.pathname === "/google" || location.pathname === "/linkedin" || location.pathname === "/instagram" ? true : false)
  }, [])

  const [showDivColor, setShowDivColor] = useState(true);

  useEffect(() => {
    if (screenWidth >= 768 && (location.pathname === "/" || location.pathname === "/facebook" || location.pathname === "/google" || location.pathname === "/linkedin" || location.pathname === "/instagram")) {
      setTimeout(() => {
        setShowDivColor(true);
      }, 3000);
    } else {
      setShowDivColor(true);
    }
  }, [screenWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const setDropDown = (data) => {
    setTpenDropdown(data);
  };

  return (
    <nav className={Styles[showDiv ? "animation" : "animationn"]}>
      {showDivColor && (
        <div className={Styles["main-nav"]}>
          <div className={Styles["menu-list-mobile"]}>
            {!openDropdown ? (
              <HiMenu
                className={Styles["three-lines"]}
                onClick={() => { setTpenDropdown(!openDropdown); }}
              ></HiMenu>
            ) : (
              <CgClose
                className={Styles["three-lines"]}
                onClick={() => setTpenDropdown(!openDropdown)}
              ></CgClose>
            )}
          </div>

          <div className={Styles["phone-number-mobile"]}>
            <a href="tel:+916360823123" className={Styles["phone-number-link"]}>
              <div className={Styles["icon-container"]}>
                <FaPhone style={{ color: "white", transform: "rotate(90deg)", textShadow: "0 1px 2px rgba(0, 0, 0, 0.3)", fontSize: '16px' }} /> {/* White phone icon */}
              </div>
              +91 - 6360823123
            </a>
          </div>


          <div className={Styles["logo"]}>
            <span>
              <img
                className={Styles["logo-h"]}
                alt="logo"
                src={urls.blackLogo}
                onClick={() => navigate("/")}
              ></img>
            </span>
          </div>

          <div className={Styles["menu-list"]}>
            <Links />
          </div>



          <div className={Styles["phone-number-desktop"]}>
            <a href="tel:+916360823123" className={Styles["phone-number-link"]}>
              <div className={Styles["icon-container"]}>
                <FaPhone style={{ color: "white", transform: "rotate(90deg)", textShadow: "0 1px 2px rgba(0, 0, 0, 0.3)", fontSize: '16px' }} /> {/* White phone icon */}
              </div>
              +91 - 6360823123
            </a>
          </div>



          <div className={Styles["common-logo-div"]}>
            <div
              className={
                Styles[
                appLevelConstants.headerLogo.includes(showLogo)
                  ? ""
                  : "common-logo-div-hidden"
                ]
              }
            >
              <Link to="/">
                {" "}
                <img
                  className={Styles["common-logo"]}
                  src={urls.blackLogo}
                  alt=""
                />{" "}
              </Link>
            </div>
          </div>
        </div>
      )}
      {/* <div className={Styles["menu-list-mobile"]}>
        {openDropdown ? <Links setTpenDropdown={setDropDown} /> : null}
      </div> */}
      <div className={Styles["menu-list-mobile"]}>
        {openDropdown ? (
          <div className={Styles["dropdown-container"]}>
            <div className={Styles["dropdown-background"]}></div>
            <Links setTpenDropdown={setDropDown} />
          </div>
        ) : null}
      </div>


    </nav>
  );
}