import React, { useEffect, useRef, useState } from 'react';
import Styles from './VideoResources.module.scss';

const videoData = {
    recommended: [
        { id: 101, title: 'Rebuilding Strength, Redefining Success - Shilpa K', url: 'https://www.youtube.com/embed/qLj2PYqSsWs' },
        { id: 102, title: 'Lakshmi Nayak’s 10x Transformation with Iron Lady', url: 'https://www.youtube.com/embed/125sdAgVEjo' },
        { id: 103, title: 'Unstoppable Growth: How Iron Lady Transformed My Life and Career', url: 'https://www.youtube.com/embed/wSr0iEKLkGs' },
        { id: 104, title: 'Master the Art of Negotiation with Iron Lady!', url: 'https://www.youtube.com/embed/h4jMg9cXsUQ' },
        { id: 105, title: 'Experience Seema Rajashekar remarkable journey of transformation and growth with Iron Lady', url: 'https://www.youtube.com/embed/cJYp9DHY7Vs' },
        { id: 106, title: 'Experience Sharmila Gorde\'s incredible journey of transformation and growth with Iron Lady.', url: 'https://www.youtube.com/embed/lkF67OuJfW8' }
    ],
    testimonials: [
        { id: 201, title: 'Life took a 360-degree turn after the LEP program – Dr. Reshma S', url: 'https://www.youtube.com/embed/mKm2fZIZEwU' },
        { id: 202, title: "Transformative Leadership: Lt Col Amarjeet's Journey with Iron Lady", url: 'https://www.youtube.com/embed/3gKYCOArGVU' },
        { id: 203, title: "Transformative Leadership: Anghrija's Journey with Iron Lady", url: 'https://www.youtube.com/embed/2nhdlg40Mvk' },
        { id: 204, title: "Meet Tanvi Hemant Jadhav, a shining example of Iron Lady's transformative power!", url: 'https://www.youtube.com/embed/Q0eMCzRiZKI' },
        { id: 205, title: "Meet Renimol Robert, a shining example of what’s possible with Iron Lady! 🚀", url: 'https://www.youtube.com/embed/ccZqwuGA9a0' },
        { id: 206, title: 'Breaking Through Limits: From Confidence Lost to Leadership Gained!', url: 'https://www.youtube.com/embed/P98TafL77bE' },
        { id: 207, title: 'I don’t even know how many times my salary has grown since I started the program!', url: 'https://www.youtube.com/embed/3NXgm-WEg6c' },
        { id: 208, title: 'From IT Pro to Leadership Coach - Tiju Iype', url: 'https://www.youtube.com/embed/HiW2fCH9kbU' },
        { id: 209, title: 'From Consultant to Global Strategy Expert!', url: 'https://www.youtube.com/embed/BC9n4Qp7Bq0' },
        { id: 210, title: 'From Discovery to Empowerment – Deepika’s Story', url: 'https://www.youtube.com/embed/QYOvKpzMYOs' },
        { id: 211, title: 'From Resignation to Boardrooms!', url: 'https://www.youtube.com/embed/bQg7uSnGAiU' },
        { id: 212, title: 'From Uncertainty to Unstoppable Confidence – Journey', url: 'https://www.youtube.com/embed/mBibyBlCcFw' },
        { id: 213, title: "Ashwini Cracks the Code with Iron Lady's Shameless Pitching", url: 'https://www.youtube.com/embed/y_JyS9_sRdg' }
    ],
};

const VideoResources = () => {
    const [activeTab, setActiveTab] = useState('recommended');
    const [videos, setVideos] = useState(videoData.recommended);
    const [currentVideo, setCurrentVideo] = useState(videoData.recommended[0]);
    const iframeRef = useRef(null);
    const isInitialRender = useRef(true);
    const [visibleVideos, setVisibleVideos] = useState(3);

    useEffect(() => {
        if (iframeRef.current) {
            const videoUrl = isInitialRender.current
                ? currentVideo.url
                : `${currentVideo.url}?autoplay=1`;
            iframeRef.current.src = videoUrl;
            isInitialRender.current = false;
        }
    }, [currentVideo]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setVideos(videoData[tab]);
        setCurrentVideo(videoData[tab][0]);
        setVisibleVideos(3);
        isInitialRender.current = true;
    };

    const handleVideoClick = (video) => {
        setCurrentVideo(video);
        const updatedVideos = videos.filter((v) => v.id !== video.id);
        setVideos([video, ...updatedVideos]);
    };

    const handleLoadMore = () => {
        setVisibleVideos((prev) => Math.min(prev + 3, videos.length));
    };

    return (
        <div className={Styles['video-tabs-section']}>
            <h1>Video Resources</h1>
            <div className={Styles['tabs']}>
                {Object.keys(videoData).map((tab) => (
                    <button
                        key={tab}
                        className={`${Styles['tab']} ${activeTab === tab ? Styles['active'] : ''}`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>

            <div className={Styles['content']}>
                <div className={Styles['main-video']}>
                    <iframe
                        ref={iframeRef}
                        src={currentVideo.url}
                        title={currentVideo.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <div className={Styles['video-header']}>
                        <h3>{currentVideo.title}</h3>
                        <a
                            href="https://www.youtube.com/@iamironlady?sub_confirmation=1"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={Styles['subscribe-button']}
                        >
                            Subscribe
                        </a>
                    </div>
                </div>

                <div className={Styles['related-section']}>
                    <h4>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Videos</h4>
                    <div className={Styles['related-videos']}>
                        {videos.slice(1, visibleVideos + 1).map((video) => (
                            <div
                                key={video.id}
                                className={Styles['related-video']}
                                onClick={() => handleVideoClick(video)}
                            >
                                <div className={Styles['video-thumbnail']}>
                                    <img
                                        src={`https://img.youtube.com/vi/${video.url.split('/embed/')[1]}/0.jpg`}
                                        alt={video.title}
                                        className={Styles['thumbnail']}
                                    />
                                    <div className={Styles['play-button']}></div>
                                </div>
                                <h4>
                                    {video.title.length > 36
                                        ? `${video.title.substring(0, 36)}...`
                                        : video.title}
                                </h4>
                            </div>
                        ))}
                    </div>
                    {visibleVideos + 1 < videos.length && (
                        <button
                            className={Styles['load-more-button']}
                            onClick={handleLoadMore}
                        >
                            Load More
                        </button>
                    )}
                </div>
            </div>

        </div>
    );
};

export default VideoResources;