import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import Styles from './LandingCarousel.module.scss';
import { urls } from '../../utils/urls';
import { Link } from 'react-router-dom';

const HERO_CAROUSEL_IMAGES = [
  {
    id: 1,
    imageURL: urls.hero_banner_1,
    ctaText: 'Learn More',
    ctaLink: '',
    showCTA: false
  },
  // {
  //   id: 2,
  //   imageURL: urls.hero_banner_2,
  //   ctaText: 'Learn More',
  //   ctaLink: 'https://workshops.iamironlady.com/2-Hours-masterclass',
  //   showCTA: true
  // },
  {
    id: 3,
    imageURL: urls.hero_banner_3,
    ctaText: 'Learn More',
    ctaLink: 'https://workshops.iamironlady.com/masterclass_app',
    showCTA: true
  },
  {
    id: 4,
    imageURL: urls.hero_banner_4,
    ctaText: 'Learn More',
    ctaLink: '/individualPrograms/100_Board_Members_Program',
    showCTA: true
  },
];

const LandingCarousel = () => {
  return (
    <Swiper
      spaceBetween={60}
      slidesPerView={1}
      loop={HERO_CAROUSEL_IMAGES.length > 2}
      speed={700}
      autoplay={{ delay: 7000, disableOnInteraction: false }}
      modules={[Navigation, Autoplay]}
      className={Styles.swiperContainer}
    >
      {HERO_CAROUSEL_IMAGES.map(({ id, imageURL, ctaText, ctaLink, showCTA }) => (
        <SwiperSlide key={id}>
          <div className={Styles.slide}>
            <img src={imageURL} alt={`Slide ${id}`} className={Styles.image} />
            {showCTA && (
              ctaLink.startsWith('http') ? (
                <a
                  href={ctaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={Styles.ctaButton}
                >
                  {ctaText}
                </a>
              ) : (
                <Link to={ctaLink} className={Styles.ctaButton}>
                  {ctaText}
                </Link>
              )
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default LandingCarousel;