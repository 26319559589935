import React from 'react'

const RajeshBhat = () => {
  return (
    <div style={{'textAlign': 'center'}}>
      <h1>Rajesh Bhat</h1>
      <p>Get inspired by Rajesh Bhat, the visionary behind Iron Lady.</p>
    </div>
  )
}

export default RajeshBhat